* {
    list-style: none;
    box-sizing: border-box;
}

.NavbarWrapper {
    font-size: .7em;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(255, 255, 255);
    width: 100%;
    z-index: 20;
    min-height: 5em;
}

.menu-shadow{
    border-bottom: 1px solid #2e2e2e6b;
    box-shadow: 0 2px 6px #2e2e2e52;
}
.flex-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.dropdown__sub {
    display: flex;
    padding: 0;
    gap: 2em;
    width: max-content;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dropdown__content .item {
    font-size: 1.3em;
    padding: .4em 1.5em;
    text-decoration: none;
    color: #000000;
    height: 4em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav_link_tag {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.NavSelect {
    background-color: transparent;
    border: none;
    outline: none;
    width: 6em;
    margin-top: .6em;
    margin-right: 2em;
    height: 3em;
    font-size: .6em;
}

.dropdown {
    display: flex;
    gap: .3em;
    padding: .1em 4em .1em .1em;
    font-size: 1.6em;
    position: relative;
    z-index: 1;
    margin-right: 8em;
    width: 100%;
    justify-content: space-between;
}

.BurgerCheck {
    display: none;
}

.dropdown__li {
    width: 100%;
    font-size: 16px;
}

.dropdown__list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown__link {
    min-width: 4em;
    padding: 0 2.4em 0;
    min-height: 2.7em;
    position: relative;
    z-index: 1000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.container-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .6em;
}

.user-navbar-container{
    grid-area: identity-user;
}

.user-name-letter {
    background-color: var(--principal-color-pink-alternative-3);
    border-radius: 50%;
    width: 2em;
    height: 2em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-name-letter:hover {
    cursor: pointer;
}
.container-menu-user.visible{
    position: absolute;
    display:flex;
    flex-direction: column;
}
.container-menu-user {
    display: none;
    width: max-content;
    background-color: rgb(255, 255, 255);
    border-radius: .6em;
    box-shadow: 0 0 4px #555555;
    transform: translate(20%,80%);
    gap: .4em;
}
.user-menu-button{
    padding: .8em 1em;
    cursor: pointer;
}

.user-menu-button:hover{
    background-color: var(--principal-color-pink-alternative-3);
    color: #fff;
    border-radius: .2em;
}
.shw {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img {
    display: flex;
    align-items: center;
    gap: 2em;
    margin-left: 3em;
}

.dropdown__check {
    display: none;
}

.dropdown__content {
    display: none;
    border-radius: .2em;
    font-size: .6em;
    background-color: #fff;
    box-shadow: 0 0 2px #000;
    border-top: none;
}

.nav_link_tag:hover .dropdown__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 90%;
}
.company-identity{
    font-size: 20px;
    min-width: 10em;
    margin-left: 2em;
    color: var(--principal-color-pink-alternative-4);
    cursor: pointer;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.dropdown__li .item:hover {
    background-color: var(--principal-color-pink-alternative-3);
    color: #fff;
}
.sign-up{
    border-radius: 4em;
    border: 1px solid var(--principal-color-pink-alternative-3);
    transition: color,background-color .3s;
    cursor: pointer;
}
.sign-up:hover{
    background-color: var(--principal-color-pink-alternative-3);
}
.sign-up:hover span{
    color: #fff;
}

.user-name-letter {
    color: #fff;
}

.user-name-letter:hover {
    filter: drop-shadow(0 0 2px #656565);
}


.BurgerMenu {
    display: none;
    width: 5em;
    height: 5em;
    border-radius: .6em;
}

.BurgerMenu div {
    height: .3em;
    width: 2.5em;
    background-color: #6f6f6f;
}


.logo {
    min-width: 5em;
    min-height: 4em;
    max-width: 5em;
    max-height: 4em;
    filter: drop-shadow(1px 1px 0px rgb(0, 0, 0));
}

.menu-label {
    color: rgb(1, 1, 1);
}
.navbar-navigation-grid-container{
    display: grid;
    grid-template-areas: "navigation-container access-methods identity-user";
    gap: 2em;
}
.flex-container-menu{
    display: flex;
}
.navigation-container{
    grid-area: navigation-container;
}
.flex-container-menu-reverse{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
/* .login-paragraph:hover{
    color: var(--principal-color-pink-alternative-4);
} */
.single-menu-cont:hover span{
    color: var(--principal-color-pink-alternative-4);
}
.single-menu-cont:hover svg{
    fill: var(--principal-color-pink-alternative-4);
}
.hide-identity{
    opacity: 0;
}
.access-methods-container{
    padding-left: 0;
    padding-right: 2em;
    grid-area:access-methods;
}
.hide-up-arrow{
    display: none;
}
@media (max-height: 1200px) and (max-width: 1000px) {

    .navbar-navigation-grid-container{
        grid-template-areas: 
        "identity-user"
        "navigation-container"
        "access-methods";
        gap: .5em;
        overflow-y: auto;
        padding: 1em 0 2em 2em;
    }

    .NavbarWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 6em;
        position: fixed;
        width: 100%;
        padding: 3em 1em;
        z-index: 100;
    }
    .access-methods-container{
        width: 80%;
    }

    .company-identity{
        margin-left: 0;
        width: 80%;
    }
    .nav_link_tag.sign-up-button-container{
        display: block;
    }
    .user-greeting{
        cursor:default;
    }
    .nav_link_tag:hover .dropdown__content {
        display: grid;
        position: relative;
        top: 0;
    }
    
    .container-user {
        margin-top: 1em;
    }

    .dropdown__content {
        background-color: transparent;
        box-shadow: none;
    }

    .img {
        display: flex;
        align-items: center;
        gap: 1.8em;
    }

    .nav {
        display: flex;
        align-items: center;
        gap: 7em;
    }

    
    .flex-container-menu{
        flex-direction: column;
        margin-top: 2em;
        padding: 0;
    }
    
    .dropdown__list {
        display: block;
    }

    .dropdown__check {
        display: block;
    }
    div.dropdown__content__visible {
        grid-template-rows: 1fr;
    }

    .dropdown__content {
        position: static;
        border: none;
        display: grid;
        grid-template-rows: 0fr;
        transition: .3s grid-template-rows;
    }

    .dropdown__list {
        list-style: none;

    }

    .flex-container-menu-reverse{
        display: flex;
        flex-direction: column-reverse;
        padding: 0 2em;
    }
    .NavSelect {
        position: absolute;
        left: .8em;
        font-size: 16px;
        bottom: 4em;

        background-color: rgb(255, 255, 255);
    }

    .dropdown__link {
        padding: 1em 0.7em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative;

    }

    .dropdown__sub {
        overflow: hidden;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .dropdown__li {
        width: 100%;
        list-style: none;
        margin-left: auto;
    }

    .BurgerMenu {
        display: flex;
        flex-direction: column;
        gap: .5em;
        position: absolute;
        align-items: center;
        cursor: pointer;
        z-index: 20;
        position: absolute;
        top: 1em;
        right: 2em;
        justify-content: center;

    }

    .dropdown__span {
        font-size: 1.6em;
    }

    .bottom-line,
    .top-line,
    .center {
        transition: all 0.6s;
    }

    .BurgerCheck {
        display: block;
        opacity: 0;
        position: absolute;
        top: 1em;
        right: 2em;
        z-index: 200;
        min-width: 7em;
        min-height: 7em;
    }

    .button-submenu{
        cursor: pointer;
    }

    .dropdown__check {
        position: absolute;
        width: 200%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    .dropdown {
        display: flex;
        padding: 0;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        top: 0;
        height: 100%;
        background-color: #fff;
        margin-right: 0;
        right: -100%;
        box-shadow: 0 0 2px #555555;
        margin-top: 0;
        transition: right 0.3s ease-in-out;
        width: 80%;

    }

    .principal-menu-visible {
        right: 0;
        transition: right 0.3s ease;
    }

    .bottom-line,
    .top-line,
    .center {
        transition: all 0.6s;
    }

    .bottom-line-second-form {
        transform: rotate(45deg);
        position: absolute;
        border-radius: .7em;
    }

    .top-line-second-form {
        transform: rotate(-45deg);
        position: absolute;
        border-radius: .7em;
    }

    div.center-second-form {
        width: 0;
        position: absolute;
    }

    .dropdown__content .item {
        font-size: 16px;
    }

    .user-name-letter {
        width: 3.5em;
        height: 3.5em;
        cursor: pointer;
    }

    .gret {
        margin-top: 2em;
        font-size: 1.3em;
        color: #000000;
    }

    .container-menu-user {
        position: absolute;
        margin: 1em;
        transform: translate(20%,10%);
        z-index: 1100;
    }
}