.docs-header{
    background-image: url("../resources/mother-daughter-doing-homework-together.jpg");
}

.slider-section{
    height: 20em;
    background-color: var(--principal-color-pink-alternative-5);
}
.grid-docs-content{
    display: grid;
    grid-template-columns: repeat(3,25em);
    gap: .5em;
}
.card-short-description{
    color: rgb(99, 99, 99);
    height: 4em;
    overflow: hidden;
}

.card-medium-description{
    color: rgb(99, 99, 99);
    overflow: hidden;
}
.card-image{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-size: cover;
    width: 100%;
}