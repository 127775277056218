*{
  box-sizing: border-box;
  font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif !important;
  --principal-color-pink : #ffaaaa;
  --principal-color-pink-alternative-5 : #fedcdc;
  --principal-color-pink-alternative-3 : #f98596;
  --principal-color-pink-alternative-4 : #f27474;
  --principal-color-pink-alternative-1 : #d85858;
  --principal-color-pink-alternative-2 : #d84444;
  --principal-color-blue : #7da9f5;
  --principal-color-purple : #b37df5;
  
}