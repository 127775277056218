.customer-contact-form-header{
    background-image: url("../resources/customer-service.jpg");
}
.customer-contact-form-starter{
    width: 100%;
}
.customer-contact-form-starter-container{
    padding: 1em 2em;
    border: 1px solid #7b7b7b7c;
    border-radius: .5em;    
}
.contact-form-quill-container{
    min-height: 15em;
}
.custom-submit-contact-form{
    width: 10em;
    text-align: center;
}
/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .customer-contact-form-starter-container{
        padding: .5em 1em;
    }
    .contact-form-quill-container{
        min-height: 20em;
    }
}
