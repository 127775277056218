div.babysitter-main-container{
    margin-top: 7%;
}
div.babysitter-form svg{
    fill: #000;
    padding-left: .3em;
}
.ContInpPlaceholder.babysitter-form{
    border: 1px solid #424242;
    align-items: center;
    font-size: 1.1em;
    border-radius: .7em;
}

/* FLATPICKR */
.reservation-titles{
    padding-bottom: .5em;
    width: max-content;
    border-bottom: 1px solid var(--principal-color-pink);
}
.flatpickr-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.ContInpPlaceholder.babysitter-form label.birth-label,.babysitter-form.ContInpPlaceholder input:valid ~ .placeholder.birth-label{
    margin-top: .7em;
    margin-left: .8em;
}
.ContInpPlaceholder.babysitter-form label.flatpickr-active,.babysitter-form.ContInpPlaceholder input:focus ~ .placeholder.birth-label{
    margin-top: -2em;
    margin-left: -.7em;
}
.flatpickr-container .flatpickr-input:active,.flatpickr-container .flatpickr-input:focus,.flatpickr-container .flatpickr-input:focus-visible{
    border: unset;
    outline: unset;
    border-left: 1px solid var(--principal-color-pink);
}
.babysitter-form{
    min-width: 22em;
}

.babysitter-form .placeholder{
    font-weight: 440;
    font-size: .95em;
    margin-left: 3.4em;
}
.hidden-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.flatpickr-container .flatpickr-input{
    border: none;
    border-left: 1px solid #424242;
    height: 3.7em;
    width: 100%;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: #fff;
}

.babysitter-form.ContInpPlaceholder input:valid~.placeholder,
.babysitter-form.ContInpPlaceholder input:focus~.placeholder{
    margin-left: 2em;
    margin-top: -5em;
}

.ContInpPlaceholder.babysitter-form .form-input:focus{
    border: unset;
    border-left: 1px solid var(--principal-color-pink);
    outline: unset;
}
.submit-button{
    padding: 1em 2em;
    outline: none;
    border: none;
    font-size: 1.1em;
}
.ContInpPlaceholder.babysitter-form:focus-within svg{
    fill: var(--principal-color-pink);
}
.ContInpPlaceholder.babysitter-form:focus-within{
    border: 1px solid var(--principal-color-pink);
    outline: 1px solid var(--principal-color-pink);
}
.ContInpPlaceholder.babysitter-form .form-input,.ContInpPlaceholder.babysitter-form .custom-select-wrapper{
    border-top-right-radius: .7em;
    border-bottom-right-radius: .7em;
    width: 100%;
    border: none;
    border-left: 1px solid #424242;
}

/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .reservation-titles-container h2{
        font-size: 1.4em;
    }
    .reservation-titles-container{
        /* text-align: center; */
    }
    .babysitter-form{
        min-width: unset;
        max-width: 20em;
    }
}