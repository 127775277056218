
*{
    --blue-card-main-color:#5fc3ff;
    --blue-card-alt-color:#58bfff;
    --blue-card-alt-color-3:rgba(95, 195, 255,0.1);

    --orange-card-main-color:#fea054;
    --orange-card-alt-color-2:#fea359;
    --orange-card-alt-color-3:rgba(254, 160, 84,0.1);

    --purple-card-main-color:#9085f9;
    --purple-card-alt-color-2:#9086f8;
    --purple-card-alt-color-3:rgba(144, 133, 249,0.1);

    --pink-card-main-color:#f98596;
    --pink-card-alt-color-2:#f896a4;
    --pink-card-alt-color-3:rgba(249, 133, 150,0.1);
}
div.blue-card{
    border: 3px solid var(--blue-card-main-color);
    background-color: var(--blue-card-alt-color-3);
}
/* BLUE CARD */
div.blue-card .container-svg-card{
    background-color: var(--blue-card-main-color);
    box-shadow: 0 14px 20px var(--blue-card-main-color);
}

div.blue-card .card-main-title{
    color: var(--blue-card-alt-color);
}

div.blue-card .card-main-title-container svg path{
    fill: var(--blue-card-alt-color);
    stroke: var(--blue-card-alt-color);
}
div.blue-card .card-secondary-title{
    color: var(--blue-card-alt-color);
}
/* ORANGE CARD */
div.orange-card{
    border: 3px solid var(--orange-card-main-color);
    background-color: var(--orange-card-alt-color-3);
}

div.orange-card .container-svg-card{
    background-color: var(--orange-card-main-color);
    box-shadow: 0 14px 20px var(--orange-card-main-color);
}

div.orange-card .card-main-title{
    color: var(--orange-card-alt-color-2);
}

div.orange-card .card-main-title-container svg path{
    fill: var(--orange-card-alt-color-2);
    stroke: var(--orange-card-alt-color-2);
}
div.orange-card .card-secondary-title{
    color: var(--orange-card-alt-color-2);
}

/* PINK CARD */
div.pink-card{
    border: 3px solid var(--pink-card-main-color);
    background-color: var(--pink-card-alt-color-3);
}

div.pink-card .container-svg-card{
    background-color: var(--pink-card-main-color);
    box-shadow: 0 14px 20px var(--pink-card-main-color);
}

div.pink-card .card-main-title{
    color: var(--pink-card-alt-color-2);
}

div.pink-card .card-main-title-container svg path{
    fill: var(--pink-card-alt-color-2);
    stroke: var(--pink-card-alt-color-2);
}
div.pink-card .card-secondary-title{
    color: var(--pink-card-alt-color-2);
}

/* PURPLE CARD */
div.purple-card{
    border: 3px solid var(--purple-card-main-color);
    background-color: var(--purple-card-alt-color-3);
}

div.purple-card .container-svg-card{
    background-color: var(--purple-card-main-color);
    box-shadow: 0 14px 20px var(--purple-card-main-color);
}

div.purple-card .card-main-title{
    color: var(--purple-card-alt-color-2);
}

div.purple-card .card-main-title-container svg path{
    fill: var(--purple-card-alt-color-2);
    stroke: var(--purple-card-alt-color-2);
}
div.purple-card .card-secondary-title{
    color: var(--purple-card-alt-color-2);
}
/*  */

/* MAIN CARD */
div.main-card{
    font-size: 16px;
    min-height: 20em;
    max-width: 17em;
    border-radius: .4em;
    padding: 1.8em;
}
.container-svg-card{
    border-radius: 50%;
    margin-top: 2em;
}

div.container-svg-card-inner svg{
    height: 4em;
    width: 4em;
    fill: #fff;
}

.container-svg-card-inner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6em;
    height: 6em;
    margin: .4em;
    padding: .8em;
    border: 3px solid #fff;
    border-radius: 50%;
}
.card-main-title{
    font-size: 20px;
    font-weight: 600;
}

.card-secondary-title{
    font-size: 1em;
    font-weight: 600;
}
/* .card-text{
    font-size: 1em;
} */


/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .container-cards{
        flex-direction: column;
        font-size: 1em;
    }
}