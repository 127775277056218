*{
    --error-color : #f00;
    --success-color : #0d0;
    --warning-color : rgb(191, 191, 115);
    --info-color : rgb(31, 102, 128);
}
.alert {    
    position: fixed;
    top: 50%; 
    right: 1rem; 
    transform: translateY(-50%);
    transition: right .5s;
    border-radius: .3em;
    font-size: 1.5em;
    background-color: #fff;
    display: grid;
    align-items: center;
    box-shadow: 0 0 2px #414141;
    grid-template-rows: 90% 10%;
    max-width: 20em;
}
.alert.starting{
    right: .4em;
}
.alert-icon{
    width: 3em;
    height: 3em;
}


.alert-content-container{
    padding: .5em 1.5em;
    display: flex;
    justify-content: space-between;
    gap: 2em;
    align-items: center;
}
  
#alert-progress-bar {
    width: 1%;
    height: .4em;
    background-color: rgb(174, 174, 174);
    border-bottom-right-radius: .3em;
    border-bottom-left-radius: .3em;
}
.alert-info{
    color: var(--info-color);
}
.alert-error{
    color: var(--error-color);
}
.alert-success{
    color:var(--success-color);
}
.alert-warning{
    color: var(--warning-color);
}
#alert-progress-bar.alert-error{
    background-color: var(--error-color);
}
#alert-progress-bar.alert-info{
    background-color: var(--info-color);
}
#alert-progress-bar.alert-success{
    background-color:var(--success-color);
}
#alert-progress-bar.alert-warning{
    background-color:var(--warning-color);
}
img.alert-warning{
    border-radius:50%;
    border:2px solid var(--warning-color);
}
img.alert-info{
    border-radius:50%;
    border:2px solid var(--info-color);
}

@media (max-height: 1000px) and (max-width: 1000px) {
    .alert{
        height: 4em;
        position: absolute;
        bottom: 20%;
        right: -50%;
        transition: right .5s;
        border-radius: .3em;
        font-size: 1.5em;
        background-color: #fff;
        display: grid;
        align-items: center;
        box-shadow: 0 0 2px #414141;
        grid-template-rows: 90% 10%;
        transform: scale(.8);
        right:-80% ;
    }   
    .alert.starting{
        right: -7%;
    }
}