.terms-section{
    margin-top: 6%;
}

/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .terms-section{
        font-size: .9em;
    }

}