.help-header{
    background-image: url("../resources/2148737505.jpg");
}
.section-container{
    margin-top: 4%;
    margin-bottom: 5%;
}
.contact-form-button{
    font-size: 16px;
}
.contact-text{
    font-size: 20px;
}
.search-bar-main-body .search-bar{
    margin: 0;
}
.main-content-help{
    margin-top: 3em;
}
.content-questions{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}
div.container-contact-form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-img-title{
    font-size: 3em;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 0 2px 5px rgba(0,0,0,.6);
    z-index: 1;
}
.grid-help-content{
    display: grid;
    width: 70%;
    align-self: center;
    grid-template-columns: repeat(2,45%);
    gap: 1em;
    justify-content: center;
    align-items: center;
}