
.security-header{
    background-image: url("../resources/security-images/trust.jpg");
}
.grid-3-columns-alt-2{
    gap: 1em;
}
.extra-font-security-card{
    font-size: 16px;
}


.standard-card-img{
    grid-area: standard-card-section1;

    background-color: var(--principal-color-pink);
}
.standard-card-text{
    grid-area: standard-card-section2;
    padding: 1em;
}
.standard-card-svg-section{
    background-color: var(--principal-color-pink);
    /* border-radius: 4em; */
    border-top-right-radius: 3em;
    border-bottom-right-radius: 3em;
    color: #fff;
    font-size: 1.3em;
}
.standard-card{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-areas: "standard-card-section2 standard-card-section1";
    box-shadow: 0 7px 10px #4d4d4d47;
    border-radius: 4em;
}
.standard-card-text{
    font-size: 1.4em;
    color: var(--principal-color-pink);

}



/* .media-cover-dark{
    height: 30.5em;
    background-color: #222;
    bottom: 0;
    content: " ";
    left: 0;
    opacity: .3;
    position: absolute;
    right: 0;
    top: 0;
} */


/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .extra-font-security-card{
        font-size: 1em;
    }   
    div.standard-card-text{
        font-size: 16px;
    }

    .grid-3-columns-alt-2{
        gap: 5em;
    }

    .standard-card{
        grid-template-columns: 80%;
        /* grid-template-rows: 40% 60%; */
        grid-template-areas: 
        "standard-card-section1" 
        "standard-card-section2";
        /* grid */
        width: 20em;
    }
    div.standard-card-svg-section{
        width: 15.4em;
    }
}