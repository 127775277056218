div.pre-reservation-main-container{
    margin-top: 7%;
}
.grid-area-pre-requisites{
    display: grid;
    grid-template-areas: 
    "type1 type2"
    "list1 list2";
}
div.babysitter-main-container{
    margin-top: 7%;
}
.type1{
    grid-area: type1;
}
.type1{
    grid-area: type1;
}
.list1{
    grid-area: list1;
}
.list1{
    grid-area: list1;
}

/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    .grid-area-pre-requisites{
        display: grid;
        grid-template-areas: 
        "type1"
        "list1"
        "type2"
        "list2";
    }
}