*{
    --principal_color_select:var(--principal-color-pink)
}
.custom-select-container{
    height: 100%;
}


.custom-select{ 
    border-top-right-radius: .3em;
    border-bottom-right-radius: .3em;
    height: 100%;
    width: 100%;
    padding: .5em 1em;
    font-size: .9em;
}

.custom-select-triangle-icon{
    /* width: 8%;      */
    width: 1em;     
    fill: var(--principal_color_select);
    transition: transform .3s;
}
.custom-select-triangle-icon.rotate{
    transform: rotate(180deg);
}
.custom-select-flex-header-content{
    min-height: 1.44em;
    display: grid;
    grid-template-columns: 80% 20%;
    justify-content: space-around;
    gap: 1em;
    align-items: center;
    height: 100%;
}

.custom-select-dropdown-content{
    display: none;
}
.dropdown-content-overflow{
    overflow-y: scroll;
    width: 100%;
}
li.no-results{
    display: none;
}
.no-options{
    width: 100%;
}
.custom-select-dropdown-content.dropdown-visible{
    display: flex;   
    font-size: .8em;
    flex-direction: column;
    box-shadow: 0 3px 4px #3a3a3a38;
    z-index: 90;
    background-color: #fff;
    justify-content: center;
    max-height: 30%;
    min-height: 20em;
    align-items: flex-start;
    width: inherit;
    position: absolute;
    padding: 0;
    margin-top: .2em;
    border-radius: .4em;
}
.custom-select-dropdown-option{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
}
.custom-select-dropdown-option:hover{
    background-color: var(--principal-color-pink);
    color: #fff;
}
li.search-bar-container input.search-bar:focus{
    border: unset;
}
li.search-bar-container{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-bar{
    width: 100%;
    border-radius: .6em;
    border: 1px solid var(--principal-color-pink);
    margin-top: .8em;
    margin-bottom: .8em;
    font-size: 1em;
}

/* .custom-select-placeholder{
} */

/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    
}
