#principal-footer{
    background-color: #333333;
    color: #fff;
    padding: 3em;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    gap: 3em;
    align-items: center;
    font-size: 16px;
}

div.grid-footer{
    align-items: flex-start;
    gap:4em;
    grid-template-columns: 20% 40% 20% 20%;
}
.footer-separator{
    width: 80%;
    height: .2em;
    background-color: #f98596;
}
.container-footer-title{
    width: 100%;
}
.footer-title{
    font-size: 16px;
    padding-bottom: .2em;
    border-bottom: 1px solid #ffffff;
    color: #f98596;
}
.footer-group .flex-container{
    padding: .4em 0;
    justify-content: flex-start;
}
.footer-group{
    font-size: 1em;
    width: 100%;
}
div.grid-group{
    justify-content: flex-start;
}


/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){
    #principal-footer{
        font-size: 1em;
    }
    div.grid-footer{
        grid-template-columns: 1fr;
        justify-content: center;
    }
    .grid-group{
        justify-content: center;    
    }
}