.formLogin{
    color: #2c2c2c;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5em; 
    min-width: 40%;
    height: 100%;
    grid-area: main-content;
}
.placeholder{
    color: #000;
    font-weight: 340;
}

div.header-login{
    width: 100%;
    grid-area: header-login;
    justify-content: space-between;
    align-items: center;
}
/* CLOSE CROSS IN LOGIN */

.container-close-login{
    display: flex;
    justify-content: flex-end;
}
.close-login{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;
    height: 2.5em;
    cursor: pointer;
}
.close-login:hover{
    border-radius: 50%;
    background-color: #b6b6b661;
}

.close-login:active{
    background-color: #8e8e8e61;
}
.close-login div{
    position: absolute;
    background-color: #555555;
    width: .2em;
    height: 1em;
}
.left-cross-part{
    transform: rotate(-45deg);
    border-radius: .3em;
}
.right-cross-part{
    transform: rotate(45deg);
    border-radius: .3em;
}



.grid-blocks{
    margin: 0 auto;
    display: grid;
    width: 80%;
    padding: 4%;
    grid-template-areas: 
    "header-login"
    "."
    "main-content"
    "main-content"
    "main-content"
    "."
    "footer-login"
    ;
    height: 100%;
    justify-content: center;
    align-items: center;

}

.flex-end-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.flex-end-container :hover{
    color: var(--principal-color-pink-alternative-1);
}

.options-to-continue{
    cursor: pointer;
    border-radius: .4em;
    padding: .6em 1em;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: background-color .3s;

}
.facebook-container{
    background-color: #2A5297;
}

.facebook-container:hover svg{
    fill:#2A5297;
}

.facebook-svg-alternative{
    fill:#fff;
}

.options-to-continue:hover{
    background-color: #fff;
}

.options-to-continue:active{
    background-color: #fff;
}

.continue-with-container{
    grid-area: footer-login;
}

.options-to-continue-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em;
}

.flex-end-container :active{
    color: var(--principal-color-pink-alternative-3);
}


.container-login-image{
    display: flex;
    justify-content: flex-start;
    height: 99%;
    image-rendering: smooth;
}
.container-login-image img{
    border-radius: .8em;
    width: 100%;
}
.loginCard{
    box-shadow: 0 3px 10px #4e4e4e63;
    width: 74%;
    min-height: 45em;
    background-color: rgb(255, 255, 255,0.5);
    /* min-height: 60em; */
    backdrop-filter: blur(.8rem);
    border-radius: 1em;
    display: grid;
    grid-template-columns: 55% 45%;
    justify-content: space-between;
    align-items: center;
    padding: .5em;
}
.loginPaddingDiv{
    position: absolute;
    display: flex;
    justify-content: center;
    /* font-size: .78em; */
    align-items: center;
    /* background-color:  #adbdc460; */
    /* background-image: url("../resources/svg/cartoon-sky-clouds.svg"); */
    /* background-image:url("../resources/cartoon-sky-clouds-mobile.jpg"); */
    
    background-image:url("../resources/cartoon-sky-clouds-mobile-pink.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}
.fotInpLog{
    display: block;
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em ;
}
.Error{
    width: 15em;
}
.display-block{
    display: block;
}
div.no-background-color{
    background-color: transparent;
}
.buttonLogin{
    border:1px solid var(--principal-color-pink);    
    font-size: 0.9em;
    margin-top: 1em;
    width: 100%;
    height: 3em;
    background-color: transparent;
    border-radius: 0.5em;
    transition: all .3s;
    cursor: pointer;
}
.buttonLogin:hover{
    background-color: var(--principal-color-pink);
    color: rgb(255, 255, 255);
}

.buttonLogin:active{
    background-color: var(--principal-color-pink-alternative-3);
    color: rgb(255, 255, 255);
}
.LoginP{
    font-size: 1.5em;
}
.TextLog{
    font-size: 0.9em;
    padding: 1em;
}

input.inputLogin{
    font-size: 0.9em;
    border-radius: .5em;
    height: 2em;
    padding: 1.5em;
    border: none;
    width: 100%;
    resize: none;
    outline: none;   
    background-color: rgb(248, 248, 248);
}
div.ContInpPlaceholderLog
{
    width: 100%;
    box-shadow: 0 2px 4px rgb(201, 201, 201);
    background-color: var(--principal-color-pink);
    border-radius: .5em;
    padding-right: .7em;
    justify-content: flex-start;
    align-items: center;
}
div.ContInpPlaceholderLog .placeholder
{
    margin-left: 1.8em;
    margin-top: .2em;
}

div.ContInpPlaceholderLog input:valid~.placeholder,
div.ContInpPlaceholderLog input:focus~.placeholder{
    display: block;
    margin-left: 1em;
    margin-top: -5em;
}
.verify-code-ban{
    filter: grayscale(1);
    color: #555555;
    cursor:not-allowed;
}
.input-field {
    display: flex;
    gap: 1em;
    flex-direction: row;
    column-gap: 10px;
  }
  .input-field input {
    height: 4em;
    width: 3em;
    border-radius: .5em;
    outline: none;
    font-size: 1.125rem;
    text-align: center;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: .5em;
    
}
  .input-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    outline: 2px solid var(--principal-color-pink);
  }
  .input-field input[type=number]::-webkit-inner-spin-button,
  .input-field input[type=number]::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  .input-field input[type=number] { -moz-appearance:textfield; }
/* better size for small devices that had a row direction flex */
@media (min-width: 700px) and (max-width: 1280px){
    .loginCard{
        width: 95%;
        height: 80%;
        min-height: 0;
    }
    div.container-login-image{
        min-height: 100%;
    }
    
    .grid-blocks{
        width: 100%;
    }
}
/* mobile design don't delete me o you may experiment a 3d effect*/
@media (max-width: 700px) and (max-height: 1000px){


    .loginPaddingDiv{
        /* background-image: url("../resources/cartoon-sky-clouds-mobile.jpg");  */
        background-image: url("../resources/cartoon-sky-clouds.svg");
        /* font-size: 1.4em; */
        font-weight: 300;
        
    /* background-image:url("../resources/svg/cartoon-sky-clouds.svg"); */
    }
    /* HANDLE SIGN UP OVERFLOW */
    div.container-login-image.sign-up-image-container{
        height: 30%;
        display: none;
    }
    .loginCard{
        display: flex;
        flex-direction: column;
        width: 85%;
        backdrop-filter: blur(.8rem);
        height: 95%;
    }
    div.smaller-height-login-card{
        height: 80%;
    }
    
    div.header-login{
        justify-content: space-around;
    }

    .container-close-login{
        justify-content: flex-start;
    }

    .continue-with-container{
        transform: scale(.8);
    }
    div.grid-blocks{
        padding: 0 1em;
        width: 100%;
        justify-content: unset;
    }
    .formLogin{
        width: 95%;
        margin: 0 auto;
    }
    div.container-login-image{
        height: 45%;

    }
}
/* handle 2k+ devices */
@media (min-width: 1920px){
    .loginCard{
        font-size: 16px;
    }
    
}
/* helps with the image in the flex container that is showed small */
@media (min-width: 412px) and (max-width: 900px) and (max-height: 1290px){
    
    div.container-login-image{
        height: 55%;
    }
    div.loginCard{
        gap: .3em;
    }
    
}

/* small devices first aid package v2 for medium devices font size helps a lot with small devices */
@media (max-width: 550px) and (min-height: 660px) and (max-height: 800px){
    .loginCard{
        font-size: 80%;
    }

    div.container-login-image{
        height: 40%;
    }
    .formLogin{
        width: 100%;
    }
    
}
/* small devices first aid package font size helps a lot with small devices */
@media (max-width: 550px) and (max-height: 660px){
    .loginCard{
        font-size: 50%;
    }
    
}
/* no way that exist a device with that dimensions but.. murphy law */
@media (max-height: 400px){
    div.container-login-image{
        display: none;
    }
    div.loginCard{
        display: flex;
        align-items: center;
        font-size: .7em;
        justify-content: center;
        height: 90%;
        width: 80%;
        min-height: 0;
    }
    
}